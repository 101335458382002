<script setup>
import { computed } from "vue";
import PartName from '@/components/admin/parts/components/name.vue';
import tractorPartDrawer from '@/components/admin/tractors/tractorPartDrawer.vue';

const emit = defineEmits(['requests-picking']);
const props = defineProps({
    item: Object,
    buttonText: {
        type: String,
        default: 'Pick',
    },
    mobileLayout: {
        type: Boolean,
        default: false,
    },
    showBorder: {
        type: Boolean,
        default: false,
    },
    locationsRoute: {
        type: String,
        default: '',
    },
});

const partPlaceholderImage = window.placeholderImage;
const qtyToPick = computed(() => props.item.total_quantity - props.item.picked_quantity);
</script>

<template>
    <div v-if="mobileLayout">
        <div class="d-flex gap-2"
             :class="{ 'border-bottom pb-2 mb-2': showBorder }">
            <img :src="item.part_image ? item.part_image : partPlaceholderImage"
                 alt="Part Image"
                 class="img-thumbnail thumbnail-xs object-fit-cover" />
            <div>
                <PartName :item="item" />
            </div>
            <div class="flex-grow-1">
                <div class="d-flex justify-content-end">
                    <button
                        type="button"
                        class="btn btn-sm flex-fill flex-sm-grow-0 text-nowrap btn-outline-secondary"
                        @click="emit('requests-picking', item)"
                    >
                        <i class="fa fa-box-open me-1"></i> {{ buttonText }}
                    </button>
                </div>
            </div>
        </div>
        <div v-if="item.tractor_part" class="ps-4 ms-3 mt-2">
                <div class="d-flex align-items-center gap-1 text-muted">
                    <TractorPartDrawer
                        v-if="item.tractor_part.quick_view_url"
                        :source-url="item.tractor_part.quick_view_url"
                    />
                    Dismantled from {{ item.tractor_part.tractor.name }}
                </div>
                <div v-if="item.tractor_part.location && item.tractor_part.location.name">
                    Located at: {{ item.tractor_part.location.name }}
                </div>
            </div>
        <div class="ps-4 ms-3 mt-2">Qty: {{ qtyToPick }}</div>
    </div>
    <tr v-else>
        <td>
            <div class="d-flex gap-2">
                <img :src="item.part_image ? item.part_image : partPlaceholderImage"
                     alt="Part Image"
                     class="img-thumbnail object-fit-cover"
                     style="width: 4rem; height: 4rem;" />
                <div class="d-flex flex-column gap-2">
                    <PartName :item="item" />
                    <div v-if="item.tractor_part">
                        <div class="d-flex align-items-center gap-1 text-muted">
                            <tractorPartDrawer v-if="item.tractor_part.quick_view_url"
                            :sourceUrl="item.tractor_part.quick_view_url"></tractorPartDrawer>
                            Dismantled from {{ item.tractor_part.tractor.name }}
                        </div>
                        <div v-if="item.tractor_part.location && item.tractor_part.location.name">
                            Located at: {{ item.tractor_part.location.name }}
                        </div>
                    </div>
                </div>
            </div>
        </td>
        <td class="text-center quantity">
            {{ qtyToPick }}
        </td>
        <td>
            <div class="d-flex align-items-center justify-content-center">
                <button
                    type="button"
                    class="btn btn-sm flex-fill flex-md-grow-0 text-nowrap btn-outline-secondary"
                    @click="emit('requests-picking', item)"
                >
                    <i class="fa fa-box-open me-1"></i> {{ buttonText }}
                </button>
            </div>
        </td>
    </tr>
</template>
