<template>
    <div class="block block-rounded">
        <div class="block-header block-header-default d-flex">
            <h3 class="block-title">{{ title }}</h3>
        </div>

        <PickModal
            ref="pickModal"
            :picking-item="pickingItem"
            :locations-route="locationsRoute"
            :locations="locations"
            :mode="wording"
            @picked="picked"
            @hidden="pickingItem = null"
        />

        <div class="block-content d-flex flex-column gap-2">
            <template v-if="allItems.length > 0">
                <table v-if="isLargeScreen"
                       class="table table-bordered table-striped table-vcenter items-table picking-list">
                    <thead>
                        <tr>
                            <th>Part</th>
                            <th class="text-center">Qty Required</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ListItem
                            v-for="(item, i) in allItems"
                            :key="i"
                            :locations-route="locationsRoute"
                            :button-text="buttonText"
                            :item="item"
                            @requests-picking="pick"
                        />
                    </tbody>
                </table>
                <div v-else>
                    <div class="d-flex flex-column gap-2 mb-4">
                        <ListItem
                            v-for="(item, i) in allItems"
                            :key="i"
                            :locations-route="locationsRoute"
                            :button-text="buttonText"
                            :item="item"
                            :show-border="i !== allItems.length - 1"
                            mobile-layout
                            @requests-picking="pick"
                        />
                    </div>
                </div>
            </template>
            <p v-else class="text-center">{{ emptyMessage }}</p>
        </div>
    </div>
</template>

<script setup>
    import ListItem from '@/components/admin/picking/requiresPicking/listItem.vue';
    import {ref, toRef} from "vue";
    import PickModal from "@/components/admin/Modals/PickModal.vue";

    const isLargeScreen = window.isLargeScreen;
    const emit = defineEmits(['requests-picking']);
    const props = defineProps({
        items: {
            type: Array,
            default: () => [],
        },
        locations: {
            type: Array,
            default: () => [],
        },
        wording: {
            type: String,
            default: 'picking',
            validator: (propValue) => {
                return ['picking', 'allocation'].includes(propValue);
            },
        },
        locationsRoute: {
            type: String,
            default: '',
        },
    });

    const allItems = toRef(props, "items");
    const pickModal = ref(null);
    const pickingItem = ref(null);

    let title, emptyMessage, buttonText;
    if (props.wording === 'picking') {
        title = 'Requires Picking';
        emptyMessage = 'No more items to pick.';
        buttonText = 'Pick';
    } else {
        title = 'Needs Allocating';
        emptyMessage = 'No more items to allocate.';
        buttonText = 'Allocate';
    }

    function pick(item) {
        pickingItem.value = item;
    }

    function picked(item, quantity, location) {
        emit('requests-picking', item, quantity, location);
        pickingItem.value = null;
    }
</script>
